import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/Homepage";
import EventPage from "./pages/EventPage";
import CompanyEventsPage from "./pages/CompanyEventsPage";
import TycUserPage from "./pages/TycUserPage";
import theme from "./theme/darktheme";
import Footer from "./components/Footer";
import ResponsiveAppBar from "./components/ResponsiveAppbar";
import GuardPrivacyPolicy from "./pages/GuardPrivacyPolicy";


function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ResponsiveAppBar />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/events" element={<EventPage />} />
          <Route path='/empresas/:id/events' element={<CompanyEventsPage />} />
          <Route path="/terminos-y-condiciones" element={<TycUserPage />} />
          <Route path="/staff-privacy-policy" element={<GuardPrivacyPolicy />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </ThemeProvider>
  );
}

export default App;

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Event, LocationOn } from "@mui/icons-material";
import capitalize from '@mui/material/utils/capitalize';
import { format, isValid } from 'date-fns';
import esLocale from 'date-fns/locale/es';

import CustomTiles from "../components/CustomTile";
import { capitalizeWords } from "../utils/capitalize";
import StoreButton from "../components/StoreButton";

function EventInfo({ event }) {

  let startDate = '';
  let startTime = '';
  let endTime = '';
  if (isValid(new Date(event.fecha_start)) && isValid(new Date(event.fecha_end))) {
    const [year, month, day] = event.fecha_start.split('-');
    startDate = format(new Date(year, month - 1, day), 'EEE, dd MMM', { locale: esLocale });
  }

  // Get the first 5 characters of the time
  if (event.hora_start) {
    startTime = event.hora_start.substring(0, 5);
  }
  if (event.hora_end) {
    endTime = event.hora_end.substring(0, 5);
  }

  let localidad = capitalizeWords(event.predio.localidad);
  let provincia = capitalizeWords(event.predio.provincia);
  let address = `${event.predio.calle} ${event.predio.altura}, ${localidad}, ${provincia}`;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
      <Typography variant="h2" fontWeight="bold">
        {event.nombre}
      </Typography>
      <Typography variant="subtitle1">Público +{event.edad}</Typography>
      <CustomTiles icon={Event} title="Fecha" subtitle={`${capitalize(startDate)} - ${startTime} - ${endTime}`} />
      <CustomTiles icon={LocationOn} title={event.predio.nombre} subtitle={address} />
      {/* <Box className='event-info-buttons' sx={{ display: 'flex', justifyContent: 'center',flexDirection: 'column', alignItems: 'center', margin: '16px' }}>
        <StoreButton type="apple"/>
        <StoreButton type="android" />
      </Box> */}
    </Box>
  );
}

export default EventInfo;